.contactmain {
  /* background-image: url('/public/Backgroundbgaps.svg'); */
  height: 100vh;
  font-family: "Inter", sans-serif;
  text-align: left;
}
.contactpage {
  /* background-image: url("/public/Backgroundbgaps.svg"); */
  height: 90%;
  display: flex;
  align-items: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form {
  /* height: 100%; */
  padding: 30px;
  background: rgba(203, 221, 221, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 60%;
  margin: 50px auto;
}

.form p {
  margin: 0;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 700;
  text-transform: capitalize;
  /* font-family: 'Patrick Hand', cursive; */
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}
.error{
    color: red;
    font-size: 10px;
    font-weight: 700;
    margin-left: 10px;
}
/* input fields */

.textareafield {
  border-radius: 10px;
  border: none;
  outline: none;
  height: 40px;
  width: 70%;
  height: 100px;
  padding: 10px;
  background-color: #e0e2f5;
  resize: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.field {
  border-radius: 10px;
  border: none;
  outline: none;
  height: 40px;
  width: 70%;
  padding-left: 10px;
  background-color: #e0e2f5;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.btn {
  height: 40px;
  width: 150px;
  border-radius: 4px;
  border: none;
  background-color: #000;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  /* font-weight: 700; */
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}
.errorContainer{
  display: flex;
  margin-left: 14%;
  margin-top: 10px;
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }
  .textareafield {
    width: 80%;
  }
  .field {
    width: 80%;
  }

  .btn {
    height: 40px;
    width: 80%;
  }
  .form {
    padding: 30px;
    width: 80%;
    margin: 50px auto;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .textareafield {
    width: 90%;
  }
  .field {
    width: 90%;
  }
  .btn {
    height: 40px;
    width: 90%;
  }
  .form {
    padding: 30px;
    width: 70%;
    margin: 50px auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }
}
