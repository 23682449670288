.notFoundContainer {
  background-color: aliceblue;
  height: 100vh;
  width: 100vw;
}

.title {
    font-size: 8rem;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text {
    font-size: 2rem;
}
.goHome {
    font-size: 1rem;
    margin-top: 100px;
    cursor: pointer;
}


@media (max-width:480px){
    .title {
        font-size: 4rem;
        height: 200px;
    }
    .text {
        font-size: 1rem;
    }
}

