.mainheader {
  padding-right: 110;
  height: 80px;
  background-color: #000000;
  color: #fff;
  display: flex;
  padding: 0 20px;
  padding-right: 10%;
  font-family: "Inter", sans-serif;
  z-index: 1;
}
.mainheaderlist {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-left: auto;
}
.headerli {
  cursor: pointer;
}

.mainheaderlogo {
  display: flex;
  align-items: center;
  margin-right: 50px;
  text-align: left;
}
.mainheaderlogo p{
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}
.titleLogo{
  display: flex;
  align-items: center;
  gap: 10px;
}
.mainheaderlogo img {
  width: 50px;
}

.mainheaderlistmobile {
  display: none;
}
.menurel {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
.menuabs {
  position: fixed;
  height: 100vh;
  background-color: #fff;
  /* width: 50vw; */
  transition: 0.4s;
  top: 0px;
  color: #444;
  right: 0;
  left: 100%;
  z-index: 1;
}
.closeicon {
  font-size: 30px;
  text-align: left;
  margin-left: 25px;
  margin-top: 25px;
}
.headerli {
  list-style: none;
  margin: 20px 0;
  padding-left: 30px;
  text-align: left;
}

@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops  */
  .mainheaderlist {
    gap: 30px;
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .mainheaderlist {
    display: none;
  }
  .mainheader {
    width: 90%;
    position: fixed;
    /* width: 80%; */
  }
  .mainheaderlistmobile {
    display: block;
    width: 100%;
  }
  .mobheader {
    display: grid;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-size: 30px;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
/* @media (min-width:1281px) { hi-res laptops and desktops } */
