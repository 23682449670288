.feature_container {
  /* display: flex; */
  width: 80%;
  margin: 50px auto;
}
.featuretxts {
  margin: 50px auto;
}
.featuretxt1 {
  font-size: 2.5rem;
  font-weight: 700;
  /* color: #2FA4FF; */
  margin: 20px 0;
  /* font-family: 'Patrick Hand', cursive; */
}

.featuretxt2 p {
  margin: 5px 0;
  font-weight: 300;
  width: 50%;
  margin: auto;
  line-height: 25px;
}
.featuregrids {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px;
}
.gridcont {
  padding: 10px;
}

.gridcont img {
  height: 90px;
  margin: 10px 0;
}
.gridtxt1 {
  font-size: 1.5em;
  margin: 10px 0;
  font-weight: 700;
}
.gridtxt2 {
  margin: 10px;
  font-size: 1em;
  font-weight: 300;
  line-height: 25px;
}

@media (max-width: 961px) {
  .feature_container {
    width: 90%;
  }
  .featuretxt2 p {
    width: 70%;
  }
  .featuregrids {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  .gridtxt1 {
    font-size: 1.2em;
  }
}

@media (max-width: 641px) {
  .featuretxt1 {
    font-size: 1.5rem;
  }
  .featuretxt2 p {
    width: 80%;
  }
  .featuregrids {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .gridtxt1 {
    font-size: 1.2em;
  }
  .gridcont {
    padding: 30px;
  }
  .gridtxt2 {
    width: 80%;
    margin: 0 auto;
  }
}
@media (min-width: 320px) {
}
@media (min-width: 481px) {
}
