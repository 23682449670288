section {
  height: 90vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 80%; */
  margin: 0 auto;
  align-items: center;
  padding-left: 10%;
  background-image: url("/public/Backgroundbgaps.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section1 {
  display: grid;
  align-items: center;
}
.section1 p {
  margin: 0;
  font-size: 3.5em;
  text-align: left;
  margin-bottom: 15px;
  font-weight: 700;
}
.section2 {
  display: flex;
  align-items: center;
}
.section2 img {
  width: 95%;
}

video {
  width: 100%;
  height: 70vh;
  outline: none;
  margin: 80px 0;
  margin-top: 120px;
}
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pausebutton {
  display: none;
}

.videoparent {
  position: relative;
  height: 100%;
  width: 100%;
}
.videotxt {
  font-family: 'Patrick Hand', cursive;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.videoplaytxt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 20px;
}

.playicon {
  font-size: 80px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playicon svg {
  /* border: 2px solid #444; */
  border-radius: 50%;
  cursor: pointer;
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  /* -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both; */
}
.videotxt h1 {
  font-size: 60px;
  margin: 0;
}
@media (max-width: 641px) {
  .section1 p {
    font-size: 2em;
  }
  video {
    height: auto;
  }
  .playicon {
    font-size: 35px;
  }
  .videoplaytxt {
    font-size: 16px;
  }
  .videotxt h1 {
    font-size: 28px;
  }
}
@media (max-width: 961px) {
  section {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    justify-content: flex-end;
    padding: 50px 0;
    height: 100%;
  }
  .section1 p {
    margin-top: 50px;
    /* font-size: 2em; */
  }
  .section2 img {
    width: 80%;
  }
  .section2 {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 320px) {
}
@media (min-width: 481px) {
}

/* 
@media (max-width: 961px) {
}
 @media (max-width: 641px) {
}
@media (min-width: 481px) {

}
@media (min-width: 320px) {

} */

/* video::-webkit-media-controls {
  display: none;
} */

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
