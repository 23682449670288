.footer {
  background-color: #0b0d17;
  /* height: 300px; */
}
.footmain {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  color: #fff;
  text-align: left;
}
.footerlist {
  display: none;
  /* display: flex; */
  gap: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  flex-wrap: wrap;
}
.footerlist li {
  list-style: none;
  /* display: flex; */
  cursor: pointer;
}

@media (max-width: 961px) {
}
@media (max-width: 641px) {
}
@media (min-width: 481px) {
}
@media (min-width: 320px) {
}
